/**
 * Created by WebStorm.
 * User: Mehedi Hasan
 * Date: 28 Jan 2025
 * Time: 12:29 PM
 * Email: mdmehedihasanroni28@gmail.com
 */

import React from 'react';
import {Flex, Progress} from "antd";
import {PLAN_RATE_TYPE} from "../../helpers/Constant";
import {formatNumber} from "../../helpers/Utils";
import {WHITE_COLOR} from "../../helpers/ChatbotConstant";
import {BILLING_SUBSCRIPTION_PATH} from "../../routes/Slug";
import {useNavigate} from "react-router-dom";

import "./plan_menu_card.scss";

const PlanMenuCard = ({currentPlan}) => {

    const navigate = useNavigate();

    return (
        <div className="plan-menu-card">
            <p className="current-plan">
                Current Plan
            </p>
            <p className="plan-name">
                {currentPlan?.current_plan_name ? currentPlan.current_plan_name : "Processing"}
            </p>

            <Flex
                justify="space-between"
                style={{height: "20px"}}
            >
                <span style={{textTransform: "capitalize"}}>
                    {PLAN_RATE_TYPE[currentPlan?.usage?.rate_type]} API Usage
                </span>
                <span>
                    {formatNumber(currentPlan?.usage?.current_usage)}/{formatNumber(currentPlan?.usage?.usage_limit)}
                </span>
                {/*{*/}
                {/*    loading ? <Spin style={{marginTop: "14px"}} indicator={<LoadingOutlined spin/>}/> :*/}
                {/*        <span>*/}
                {/*            {formatNumber(currentPlan?.usage?.current_usage)}/{formatNumber(currentPlan?.usage?.usage_limit)}*/}
                {/*        </span>*/}
                {/*}*/}
            </Flex>
            <Progress
                percent={currentPlan?.usagePercent}
                showInfo={false}
                strokeColor={{
                    from: "#00F0FF",
                    to: "#FF1CF7",
                }}
                trailColor={WHITE_COLOR}
            />

            <p
                className="upgrade-plan text-primary-gradient-color"
                onClick={() => navigate(BILLING_SUBSCRIPTION_PATH)}
            >
                Upgrade Plan
            </p>
        </div>
    );
};

export default PlanMenuCard;
