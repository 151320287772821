import React from 'react';
import {Form, Input} from "antd";
import ProfileImageUploader from "../../components/uploader/ProfileImageUploader";
import {retrieveImageUrl} from "../../helpers/Utils";
import {USER_FOLDER} from "../../helpers/Constant";

const UserProfileForm = ({form, handleSubmit, profile}) => {

    const onFinish = async values => {
        handleSubmit(values);
    }

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={
                profile ? {
                    fullName: profile.fullName,
                    email: profile.email,
                    profilePicture: profile.profilePicture
                } : {}
            }
        >
            <Form.Item
                label="Full Name"
                name="fullName"
                rules={[
                    {required: true, message: "Full name is required"}
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
            >
                <Input readOnly/>
            </Form.Item>

            <Form.Item
                label="Profile Picture"
                name="profilePicture"
            >

                <ProfileImageUploader
                    limit={1}
                    fileList={profile && profile?.profilePicture ? [retrieveImageUrl(profile?.profilePicture)] : []}
                    onRemove={() => form.setFieldsValue({profilePicture: null})}
                    folder={USER_FOLDER}
                    onUpload={profilePicture => form.setFieldsValue({profilePicture})}
                />

            </Form.Item>

        </Form>
    );
};

export default UserProfileForm;