import React from 'react';
import {Button} from "antd";

import "./default_btn.scss"

const DefaultBtn = ({btnName, onClick, icon, loading, style}) => {

    return (
        <Button
            onClick={() => onClick ? onClick() : null}
            icon={icon ? icon : null}
            loading={loading}
            className="custom-default-btn"
            style={{...style}}
        >
            {btnName}
        </Button>
    );
};

export default DefaultBtn;