import React, {createContext, useState} from 'react';
import ChatbotService from "../services/ChatbotService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const ChatbotContext = createContext("ChatbotContext");

const ChatbotContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [chatbotList, setChatbotList] = useState([]);

    const [chatbotProdLoading, setChatbotProdLoading] = useState(false);
    const [chatbotProdList, setChatbotProdList] = useState([]);
    const [chatbotLanguage, setChatbotLanguage] = useState([]);

    const [chatbotCreateLimit, setChatbotCreateLimit] = useState(0);

    const getChatbotList = async params => {
        try {

            setLoading(true);

            const res = await ChatbotService.getChatbotList(params);
            setChatbotList(res.data.content);
            setTotalElements(res.data.totalElements);
            setChatbotCreateLimit(parseInt(res.headers.total));

            setLoading(false);

        } catch (error) {

            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getLoadMoreChatbot = async params => {
        try {

            setLoading(true);

            const res = await ChatbotService.getChatbotList(params);
            setChatbotList([...chatbotList, ...res.data.content]);
            setTotalElements(res.data.totalElements);
            setChatbotCreateLimit(res.headers.total);

            setLoading(false);

        } catch (error) {

            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getChatbotListForProd = async params => {
        try {

            setChatbotProdLoading(true);

            const res = await ChatbotService.getChatbotListForProd(params);
            setChatbotProdList(res.data);

            setChatbotProdLoading(false);

        } catch (error) {

            setChatbotProdLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <ChatbotContext.Provider
            value={{
                loading,
                totalElements,
                chatbotList,
                getChatbotList,
                chatbotProdLoading,
                chatbotProdList,
                getChatbotListForProd,
                chatbotLanguage,
                setChatbotLanguage,
                chatbotCreateLimit,
                getLoadMoreChatbot
            }}
        >
            {children}
        </ChatbotContext.Provider>
    );
}

export default ChatbotContextProvider;
