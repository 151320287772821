const API_VERSION = "api/1.0.0";

const {
    REACT_APP_BASE_URL,
    REACT_APP_TELEGRAM_BASE_URL,
    REACT_APP_WHATSAPP_BASE_URL,
    REACT_APP_WORKFLOW_BASE_URL,
    REACT_APP_EP_PERFORMANCE_BASE_URL,
    REACT_APP_TELEGRAM_VIDEO_S3_URL,
    REACT_APP_WHATSAPP_VIDEO_S3_URL,
    REACT_APP_EP_WEB_CONFIG_BASE_URL,
    REACT_APP_WF_INGRESS_URL,
    REACT_APP_WORKFLOW_CHAT_URL,
    REACT_APP_WEB_CONFIG_SDK_URL,
    REACT_APP_WEB_CONFIG_PLUGIN_URL,
    REACT_APP_DEFAI_CHAT_SERVICE_URL,
    REACT_APP_BILLING_SERVICE_URL,
    REACT_APP_AGENT_BASE_URL,
    REACT_APP_WEB_CONFIG_VIDEO_URL,
} = process.env;

const BASE_URL = `${REACT_APP_BASE_URL}/${API_VERSION}`;
const EP_PERFORMANCE_BASE_URL = REACT_APP_EP_PERFORMANCE_BASE_URL;

const SECURED_URL = `${BASE_URL}/secured`;
const PUBLIC_URL = `${BASE_URL}`;

const TELEGRAM_URL = `${REACT_APP_TELEGRAM_BASE_URL}/api`;

const WHATSAPP_URL = `${REACT_APP_WHATSAPP_BASE_URL}/api`;

const WORKFLOW_URL = `${REACT_APP_WORKFLOW_BASE_URL}`;

const WEB_CONFIG_URL = `${REACT_APP_EP_WEB_CONFIG_BASE_URL}`;

export const WEB_CONFIG_SDK_URL = REACT_APP_WEB_CONFIG_SDK_URL;

// file uploader
export const USER_PROFILE_PICTURE_UPLOAD_URL = `${SECURED_URL}/s3/pic/upload`;
export const COMPANY_LOGO_UPLOAD_URL = `${SECURED_URL}/s3/settings/company-logo/update`;
export const RETRIEVE_IMAGE_URL = `${PUBLIC_URL}/s3/get-object`;

//auth
export const USER_SIGN_IN_URL = `${PUBLIC_URL}/login/request`;
export const OTP_VERIFY_URL = `${PUBLIC_URL}/verify-otp`;
export const FORGOT_PASSWORD_URL = `${PUBLIC_URL}/forget/password/request`;
export const RESEND_OTP_URL = `${PUBLIC_URL}/resend/otp`;

// social login
export const GOOGLE_LOGIN_URL = `${PUBLIC_URL}/lite/social/login`;

// chatbot
export const CREATE_CHATBOT_URL = `${SECURED_URL}/chatbots/create`;
export const GET_ALL_CHATBOT_URL = `${SECURED_URL}/chatbots`;
export const GET_ALL_CHATBOT_FOR_PROD_URL = `${SECURED_URL}/chatbots/for-prod`;
export const GET_CHATBOT_BY_ID_URL = `${SECURED_URL}/chatbots/id`;
export const UPDATE_CHATBOT_URL = `${SECURED_URL}/chatbots/update`;
export const GET_LANGUAGES_BY_CHATBOT_ID_URL = `${SECURED_URL}/chatbots/languages`;

// uat file
export const CREATE_UAT_FILE_URL = `${SECURED_URL}/uats/create`;
export const GET_ALL_UAT_FILE_URL = `${SECURED_URL}/uats`;
export const GET_ALL_UAT_VERSION_URL = `${SECURED_URL}/uats/as-list`;
export const GET_UAT_FILE_BY_ID_URL = `${SECURED_URL}/uats/id`;
export const UPDATE_UAT_FILE_URL = `${SECURED_URL}/uats/update`;
export const DOWNLOAD_UAT_FILE_URL = `${SECURED_URL}/uat/files/download/as-zip`;
export const APPLY_UAT_VERSION_PUBLISH_URL = `${SECURED_URL}/uats/publish/request`;
export const GET_ALL_UAT_FOR_MANAGER = `${SECURED_URL}/uats/for-manager`;
export const APPROVE_UAT_URL = `${SECURED_URL}/uats/request/verify`;

// uat performance
export const GET_CHATBOT_PERFORMANCE_URL = `${EP_PERFORMANCE_BASE_URL}/evaluate_chatbot/`;
export const GET_WORKFLOW_PERFORMANCE_URL = `${EP_PERFORMANCE_BASE_URL}/evaluate_workflow/`;

// production file
export const CREATE_PRODUCTION_FILE_URL = `${SECURED_URL}/productions/create`;
export const GET_ALL_PRODUCTION_FILE_URL = `${SECURED_URL}/productions`;
export const GET_ALL_PRODUCTION_HISTORY_URL = `${SECURED_URL}/productions/histories`;
export const UPDATE_PRODUCTION_FILE_URL = `${SECURED_URL}/productions/update`;
export const DISABLE_PRODUCTION_FILE_URL = `${SECURED_URL}/productions/disable/enable`;

//user
export const CHANGE_USER_PASSWORD = `${SECURED_URL}/users/change-password`;
export const GET_USER_PROFILE_URL = `${SECURED_URL}/users/profile`;
export const UPDATE_USER_PROFILE_URL = `${SECURED_URL}/users/profile/update`;
export const CREATE_USER_URL = `${SECURED_URL}/users/create`;
export const GET_ALL_USER_URL = `${SECURED_URL}/users`;
export const UPDATE_USER_URL = `${SECURED_URL}/users/update`;
export const RESEND_USER_CRED_URL = `${SECURED_URL}/users/resend-credentials`;

// language
export const GET_ALL_LANGUAGE_URL = `${SECURED_URL}/languages`;
export const GET_LANGUAGES_BY_CHATBOT_URL = `${SECURED_URL}/languages/for-chatbot`;

// settings
export const GET_SETTINGS_URL = `${SECURED_URL}/settings`;
export const UPDATE_SETTINGS_URL = `${SECURED_URL}/settings/create/update`;
export const GET_SETTINGS_API_URL = `${SECURED_URL}/settings/ep-urls-keys`;

// user activity log
export const GET_USER_ACTIVITY_LOG_LIST = `${SECURED_URL}/activity-logs/by/user`;
export const GET_API_USAGE_LIST_URL = `${SECURED_URL}/activity-logs/api-usages/by-company`;

// telegram url
export const REGISTER_TELEGRAM_URL = `${TELEGRAM_URL}/telegram/bots`;
export const GET_ALL_TELEGRAM_USER_URL = `${TELEGRAM_URL}/telegram/bots`;
export const TELEGRAM_USER_TOGGLE_URL = `${TELEGRAM_URL}/telegram/bots`;
export const DELETE_TELEGRAM_USER_URL = `${TELEGRAM_URL}/telegram/bots`;
export const TELEGRAM_VIDEO_URL = REACT_APP_TELEGRAM_VIDEO_S3_URL;
export const WHATSAPP_VIDEO_URL = REACT_APP_WHATSAPP_VIDEO_S3_URL;
export const WEB_CONFIG_VIDEO_URL = REACT_APP_WEB_CONFIG_VIDEO_URL;

// whats-app url
export const REGISTER_WHATSAPP_URL = `${WHATSAPP_URL}/whatsapp/register`;
export const GET_ALL_WHATSAPP_URL = `${WHATSAPP_URL}/whatsapp/bots`;
export const DELETE_WHATSAPP_URL = `${WHATSAPP_URL}/whatsapp/delete`;
export const WHATSAPP_TOGGLE_URL = `${WHATSAPP_URL}/whatsapp/toggle`;
export const WHATSAPP_CHANGE_ACCESS_TOKEN_URL = `${WHATSAPP_URL}/whatsapp/updateToken`;

// metrics
export const GET_MONITOR_USAGE_METRICS = `${WORKFLOW_URL}/metrics/usage`;

// workflow
export const CREATE_WORKFLOW = `${REACT_APP_WF_INGRESS_URL}`;
export const GET_ALL_WORKFLOW = `${WORKFLOW_URL}/topics`;
export const DELETE_MULTIPLE_WORKFLOW = `${WORKFLOW_URL}/topics/delete`;
export const DUPLICATE_WORKFLOW = `${WORKFLOW_URL}/topics/duplicate`;
export const GET_WORKFLOW_BY_TOPIC = `${WORKFLOW_URL}/topics`;

// web config
export const WORKFLOW_PLUGIN_SDK_MIN_URL = `${WEB_CONFIG_URL}/plugin/sdk.min.js`;

// workflow chat
export const WORKFLOW_CHAT = `${REACT_APP_WORKFLOW_CHAT_URL}`;

// text to workflow
export const CREATE_TEXT_TO_WORKFLOW_URL = `${REACT_APP_DEFAI_CHAT_SERVICE_URL}/text-to-workflow`
export const UPDATE_TEXT_TO_WORKFLOW_URL = `${REACT_APP_DEFAI_CHAT_SERVICE_URL}/text-to-workflow-edit`
export const WORKFLOW_AI_REDRAW_URL = `${REACT_APP_DEFAI_CHAT_SERVICE_URL}/ai-redraw`

// uat prod file
export const CREATE_UAT_PROD_FILE_URL = `${SECURED_URL}/lite-uat-prods/create`;
export const GET_ALL_UAT_PROD_FILE_URL = `${SECURED_URL}/lite-uat-prods`;
export const GET_ALL_UAT_PROD_VERSION_URL = `${SECURED_URL}/lite-uat-prods/histories`;
export const GET_UAT_PROD_FILE_BY_ID_URL = `${SECURED_URL}/lite-uat-prods/id`;
export const UPDATE_UAT_PROD_FILE_URL = `${SECURED_URL}/lite-uat-prods/update`;
export const DOWNLOAD_UAT_PROD_FILE_URL = `${SECURED_URL}/lite-uat-prod/files/download/as-zip`;
export const PUBLISH_UAT_PROD_URL = `${SECURED_URL}/lite-uat-prods/publish`;

// web urls
export const WEB_CONFIG_PLUGIN_URL = REACT_APP_WEB_CONFIG_PLUGIN_URL;

// billing service
export const GET_ALL_SUBSCRIPTION_PLAN = `${REACT_APP_BILLING_SERVICE_URL}/plans`;
export const GET_CURRENT_SUBSCRIPTION_PLAN_SUMMARY = `${REACT_APP_BILLING_SERVICE_URL}/summary`;
export const GET_CURRENT_SUBSCRIPTION_PLAN = `${REACT_APP_BILLING_SERVICE_URL}/plans/by-client`;
export const SUBSCRIPTION_PLAN_UPGRADE = `${REACT_APP_BILLING_SERVICE_URL}/subscription/upgrade`;
export const CANCEL_SUBSCRIPTION_PLAN = `${REACT_APP_BILLING_SERVICE_URL}/subscription/cancel`;

// payment method
export const CREATE_PAYMENT_METHOD = `${REACT_APP_BILLING_SERVICE_URL}/payment-methods`;
export const GET_ALL_PAYMENT_METHOD = `${REACT_APP_BILLING_SERVICE_URL}/payment-methods`;
export const DELETE_PAYMENT_METHOD = `${REACT_APP_BILLING_SERVICE_URL}/payment-methods/detach`;
export const MAKE_DEFAULT_PAYMENT_METHOD = `${REACT_APP_BILLING_SERVICE_URL}/payment-methods/make-default`;

// payment history
export const GET_ALL_PAYMENT_HISTORY = `${REACT_APP_BILLING_SERVICE_URL}/payment-history`;

// user agent customize
export const AGENT_BASE_URL = REACT_APP_AGENT_BASE_URL;
export const GET_USER_AGENT_URL = `${PUBLIC_URL}/user-agent/id`;
export const CREATE_USER_AGENT_URL = `${SECURED_URL}/user-agent/create`;
export const UPDATE_USER_AGENT_URL = `${SECURED_URL}/user-agent/update`;