import * as PATH from "../routes/Slug";
import * as MenuIcon from "../components/layout/menu_icons/Icon";
import ROLES from "../helpers/Roles";
import {LITE_CHATBOT_ID, LITE_CHATBOT_LANG, LITE_CHATBOT_NAME} from "../helpers/Constant";

const NavsTwo = [
    {
        key: "production-lite",
        title: "Knowledge",
        icon: MenuIcon.ChatbotIcon(),
        path: `${PATH.PRODUCTION_LITE_HISTORY_PATH}/${localStorage.getItem(LITE_CHATBOT_ID)}?name=${localStorage.getItem(LITE_CHATBOT_NAME)}&language=${localStorage.getItem(LITE_CHATBOT_LANG)}`,
        subMenu: null,
        roles: [ROLES.LITE_EP_SUPER_USER]
    },
    {
        key: "channel",
        title: "Channel",
        icon: MenuIcon.ChannelIcon(),
        subMenu: [
            {
                key: "telegram-bot-list",
                title: "Telegram",
                path: PATH.TELEGRAM_BOT_LIST_PATH,
                icon: MenuIcon.TelegramConfigIcon(),
                subMenu: null,
                roles: [ROLES.LITE_EP_SUPER_USER]
            },
            {
                key: "whatsapp-list",
                title: "Whatsapp",
                path: PATH.WHATSAPP_LIST_VIEW_PATH,
                icon: MenuIcon.WhatsAppWhite(),
                subMenu: null,
                roles: [ROLES.LITE_EP_SUPER_USER]
            },
            {
                key: "web-config",
                title: "Web Config",
                path: PATH.WEB_CONFIG_PATH,
                icon: MenuIcon.WebConfigIcon(),
                subMenu: null,
                roles: [ROLES.LITE_EP_SUPER_USER]
            },
        ],
        roles: [ROLES.LITE_EP_SUPER_USER]
    },
]

export default NavsTwo;
