import privateAPI from "../rest_handlers/privateAPI";
import * as PATH from "../configs/APIUrl";

export default class ImageUploadService {

    static uploadCompanyLogo = (id, data) =>
        privateAPI.put(`${PATH.COMPANY_LOGO_UPLOAD_URL}/${id}`, data, {
                headers: {"Content-Type": "multipart/form-data"}
            }
        );

    static uploadProfilePicture = data =>
        privateAPI.post(PATH.USER_PROFILE_PICTURE_UPLOAD_URL, data, {
                headers: {"Content-Type": "multipart/form-data"}
            }
        );

}
