import React from 'react';
import ImageView from "../../common/ImageView";
import {CHATBOT_LITE_PATH} from "../../../routes/Slug";
import {useNavigate} from "react-router-dom";

import "./brand.scss";

const Brand = ({collapsed, setCollapsed}) => {

    const navigate = useNavigate();

    return (
        <div
            className="brand"
            style={{justifyContent: collapsed ? "center" : "space-between"}}
        >

            {
                collapsed ? null : <ImageView
                    url="/assets/logo/lite_logo_one.svg"
                    style={{height: "100%"}}
                    onClick={() => navigate(CHATBOT_LITE_PATH)}
                />
            }

            <ImageView
                url="/assets/icons/collapse_icon.svg"
                onClick={() => setCollapsed(!collapsed)}
            />

        </div>
    );
};


export default Brand;