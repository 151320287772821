import React, {useEffect, useState} from 'react';
import {Upload} from 'antd';
import {getErrorMessage, retrieveImageUrl} from '../../helpers/Utils';
import ImageUploadService from '../../services/ImageUploadService';
import LoadingSuspense from "../common/LoadingSuspense";
import {Toast} from "../common/Toast";

import "./profile_image_uploader.scss";

const ProfileImageUploader = (props) => {

    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    // const [fileList, setFileList] = useState([...props.fileList.map((e, i) => ({uid: i, status: 'done', url: e}))]);

    useEffect(() => {
        setFileList(props.fileList.map((e, i) => ({
            uid: i,  // Ensure uid is a string
            status: 'done',
            url: e
        })));
    }, [props.fileList]);

    const onRemove = (file) => {
        setFileList([...fileList.filter(e => e.uid !== file.uid)]);
        if (props.onRemove) {
            props.onRemove(file.url);
        }
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadFile = async data => {

        let formData = new FormData();

        formData.append('file', data.file);
        formData.append('folder', props.folder);

        try {

            setLoading(true);

            const res = await ImageUploadService.uploadProfilePicture(formData);

            setFileList([...fileList, {
                uid: fileList.length,
                status: 'done',
                url: `${retrieveImageUrl(res.data)}`
            }])

            props.onUpload(res.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error)
            Toast("error", "Error", "Can not upload " + message);

        }
    }

    return (
        <Upload
            customRequest={uploadFile}
            listType="picture-card"
            className="profile-image-uploader"
            fileList={fileList}
            onRemove={onRemove}
            onPreview={onPreview}
        >
            {
                loading ? <LoadingSuspense/> :
                    fileList.length < props.limit && '+ Upload'
            }
        </Upload>
    );
};

export default ProfileImageUploader;
