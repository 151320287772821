import {useContext, useEffect, useState} from 'react';
import UserService from "../services/UserService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import {AuthContext} from "../contexts/AuthContextProvider";

const useProfile = () => {

    const {setProfile} = useContext(AuthContext);

    const [profileLoading, setProfileLoading] = useState(false);
    const [profile, setMyProfile] = useState(null);

    const [updateLoading, setUpdateLoading] = useState(false);

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfile = async () => {
        try {

            setProfileLoading(true);

            const res = await UserService.getProfile();
            setMyProfile(res.data.info);

            setProfileLoading(false);

            return true;

        } catch (error) {

            setProfileLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return false;
        }
    }

    const updateProfile = async data => {
        try {

            setUpdateLoading(true);

            const res = await UserService.updateUserProfile(data);
            setProfile(res.data.info);

            Toast("success", "Profile Updated", "Profile has been updated successfully");

            setUpdateLoading(false);

            return true;

        } catch (error) {

            setUpdateLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return false;
        }
    }

    return {profileLoading, profile, updateProfile, updateLoading};
};

export default useProfile;
