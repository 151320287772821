import React from 'react';
import {Drawer, Flex} from "antd";
import {Close} from "../layout/menu_icons/Icon";
import PrimaryBtn from "../buttons/PrimaryBtn";
import DefaultBtn from "../buttons/DefaultBtn";

const FormDrawer = ({
                        open,
                        closeDrawer,
                        title,
                        btnNameCancel = "Cancel",
                        btnNameOk,
                        children,
                        handleOk,
                        loading,
                        okBtnIcon = "",
                    }) => {

    return (
        <Drawer
            open={open}
            onClose={closeDrawer}
            title={<Flex justify="space-between">
                <span>{title}</span>
                <span
                    onClick={closeDrawer}
                    style={{cursor: "pointer"}}
                >
                    {Close()}
                </span>
            </Flex>}
            closeIcon={null}
            footer={<Flex justify="space-between" gap={12}>
                <DefaultBtn
                    btnName={btnNameCancel}
                    onClick={closeDrawer}
                    style={{width: "100%"}}
                />
                <PrimaryBtn
                    loading={loading}
                    onClick={handleOk}
                    icon={okBtnIcon}
                    btnName={btnNameOk}
                    style={{width: "100%"}}
                />
            </Flex>}
            width={500}
            rootClassName="form-drawer-root-class"
        >
            {children}
        </Drawer>
    );
};

export default FormDrawer;