import React, {useContext, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Form, Menu} from 'antd';
import Brand from './brand/Brand';
import {AuthContext} from "../../contexts/AuthContextProvider";
import {hasRole} from "../../helpers/Utils";
import {ArrowLeftTwo, SignOut, UserProfileIcon} from "./menu_icons/Icon";
import UserProfileForm from "../../pages/user/UserProfileForm";
import useProfile from "../../hooks/useProfile";
import FormDrawer from "../drawer/FormDrawer";
import NavsTwo from "../../configs/NavsTwo";
import {CHATBOT_LITE_PATH} from "../../routes/Slug";
import {SubscriptionContext} from "../../contexts/SubscriptionContextProvider";
import PlanMenuCard from "./PlanMenuCard";
import Roles from "../../helpers/Roles";

import "./aside_left.scss";

const AsideLeftTwo = ({collapsed, setCollapsed}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const {loggedUserRoles, logout, profile} = useContext(AuthContext);
    const {currentPlan, currentPlanLoading} = useContext(SubscriptionContext);

    const {updateProfile, updateLoading} = useProfile();

    const [profileDrawerVisible, setProfileDrawerVisible] = useState(false);

    const [stateOpenKeys, setStateOpenKeys] = useState(['']);

    const getSelectedMenu = () => {

        const menuKeyMap = {
            "production-lite": "production-lite",
            "production-lite-history": "production-lite",
            "telegram-bot-list": "telegram-bot-list",
            "web-config": "web-config",
            "whatsapp-list": "whatsapp-list",
        }

        let path = location.pathname.split("/");

        return menuKeyMap[path[1]];

    }

    const openProfileDrawer = () => {
        setProfileDrawerVisible(true);
    }

    const closeProfileDrawer = () => {
        setProfileDrawerVisible(false);
    }

    const navigateToDashboard = () => {
        navigate(CHATBOT_LITE_PATH);
    }

    /*
    * Below code used for only one menu will be open at a time
    * */

    const getLevelKeys = (items1) => {
        const key = {};
        const func = (items2, level = 1) => {
            items2.forEach((item) => {
                if (item.key) {
                    key[item.key] = level;
                }
                if (item.subMenu) {
                    func(item.subMenu, level + 1);
                }
            });
        };
        func(items1);
        return key;
    };

    const levelKeys = getLevelKeys(NavsTwo);

    const onOpenChange = (openKeys) => {

        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        // open
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys
                .filter((key) => key !== currentOpenKey)
                .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
            setStateOpenKeys(
                openKeys
                    // remove repeat key
                    .filter((_, index) => index !== repeatIndex)
                    // remove current level all child
                    .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
            );
        } else {
            // close
            setStateOpenKeys(openKeys);
        }
    };

    /* Menu Binding Start */

    const bindSingleMenuItem = (item) => {
        return (
            {
                key: item.key,
                label: <div
                    style={{fontSize: "14px"}}
                >
                    {item.title}
                    {item.path && <Link to={item.path}/>}
                </div>,
                icon: item.icon,
            }
        )
    }

    const bindSubMenuItem = item => {
        return (
            {
                key: item.key,
                label: <div style={{fontSize: "14px"}}>{item.title}</div>,
                icon: item.icon,
                children: item.subMenu.map(childItem => getMenuItems(childItem))
            }
        )
    }

    const getMenuItems = (item) => {

        if (!hasRole(loggedUserRoles, item.roles)) {
            return null;
        }

        return item.subMenu ? bindSubMenuItem(item) : bindSingleMenuItem(item);
    }

    const bottomMenuItems = [
        {
            key: "dashboard",
            label: <span onClick={navigateToDashboard}>
                Return To Dashboard
            </span>,
            icon: <span onClick={navigateToDashboard}>
                {ArrowLeftTwo()}
            </span>,
        },
        hasRole(loggedUserRoles, [Roles.LITE_EP_SUPER_USER]) ? {
            key: "plan",
            label: <PlanMenuCard
                currentPlan={currentPlan}
                loading={currentPlanLoading}
            />,
            icon: null,
            rootClassName: "my-root-class"
        } : null,
        {
            key: "profile",
            label: <span onClick={openProfileDrawer}>
                Profile
            </span>,
            icon: <span onClick={openProfileDrawer}>
                {UserProfileIcon()}
            </span>,
        },
        {
            key: "logout",
            label: <div onClick={() => logout()}>
                Logout
            </div>,
            icon: <span onClick={() => logout()}>
                {SignOut()}
            </span>
        },
    ]

    /* Menu Binding End */

    return (
        <>

            <div className="aside-left">
                <Brand
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                />
                <div className="menu-wrapper">
                    <Menu
                        theme="light"
                        mode="inline"
                        openKeys={stateOpenKeys}
                        onOpenChange={onOpenChange}
                        items={NavsTwo.map(item => getMenuItems(item))}
                        selectedKeys={getSelectedMenu()}
                        className="aside-left-content-top"
                    />
                    <Menu
                        theme="light"
                        mode="inline"
                        items={bottomMenuItems}
                        className="aside-left-content-bottom"
                    />
                </div>

                <FormDrawer
                    open={profileDrawerVisible}
                    closeDrawer={closeProfileDrawer}
                    title={`Profile of "${profile?.fullName}"`}
                    btnNameOk="Update Profile"
                    handleOk={() => form.submit()}
                    loading={updateLoading}
                >
                    <UserProfileForm
                        form={form}
                        profile={profile}
                        handleSubmit={updateProfile}
                    />
                </FormDrawer>

            </div>
        </>
    );
}

export default AsideLeftTwo;
